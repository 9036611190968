/* You can add global styles to this file, and also import other style files */
.ant-steps-item-icon .ant-steps-icon {

    top: -4.5px !important;
}

/* // Change the focus color for dropdown items */
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #8899ab !important;
    ;
}

/* // Change the focus color for the parent dropdown */
.ant-select-dropdown .ant-select-item-option-selected {
    background-color: #90a0b1 !important;
}

.selected-row,
.selected-row td {
    background-color: #c7f0ff !important;
}